// const columnsbusiness = [
//     {
//         title: '',
//         dataIndex: 'business',
//         key: 'business',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'business'
//         },
//         customRender: (value, row, index) => {
//             console.log(value, row, index);
//             const obj = {
//               children: this.countnum,
//               attrs: {},
//             };
//             if(index === 0){   // 第一行数据开始，跨行合并的长度为数据data的长度
//               obj.attrs.rowSpan = this.data.length;
//             }
//             if (index >= 1) {   // 从第一行往后的所有行表格均合并
//               obj.attrs.rowSpan = 0;
//             }
//             return obj;
//           }
//     },
//     {
//         title: '类型图标',
//         dataIndex: 'business_type_icon',
//         key: 'business_type_icon',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'business_type_icon'
//         }
//     },
//     {
//         title: '-',
//         dataIndex: 'earnest_money',
//         key: 'earnest_money',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'earnest_money'
//         }
//     },
//     {
//         title: '最低保证金余额($)',
//         dataIndex: 'balance',
//         key: 'balance',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'balance'
//         }
//     },
//     {
//         title: '等级',
//         dataIndex: 'level_name',
//         key: 'level_name',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'level_name'
//         }
//     },
//     {
//         title: '等级图标',
//         dataIndex: 'icon_url',
//         key: 'icon_url',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'icon_url'
//         }
//     },
//     {
//         title: '销售额（$）/自然月',
//         dataIndex: 'sales',
//         key: 'sales',
//         align:"center",
//         // width: '10%',
//         scopedSlots: {
//             customRender: 'sales'
//         }
//     },
//     {
//         title: '费率折扣',
//         dataIndex: 'discount_rate',
//         key: 'discount_rate',
//         align:"center",
//         // width: '5%',
//         scopedSlots: {
//             customRender: 'discount_rate'
//         }
//     },
//     {
//         title: '操作',
//         dataIndex: 'operation',
//         key: 'operation',
//         align:"center",
//         // width: '8%',
//         scopedSlots: {
//             customRender: 'operation'
//         }
//     },
// ]
var columns = [{
  title: "",
  dataIndex: "business",
  key: "business",
  align: "center",
  width: '80px',
  scopedSlots: {
    customRender: "business"
  }
}, {
  title: "类型图标",
  dataIndex: "business_type_icon",
  key: "business_type_icon",
  align: "center",
  width: '80px',
  scopedSlots: {
    customRender: "business_type_icon"
  }
}, {
  title: '-',
  dataIndex: 'earnest_money',
  key: 'earnest_money',
  align: "center",
  width: '75px',
  scopedSlots: {
    customRender: 'earnest_money'
  }
}, {
  title: '-',
  dataIndex: 'balance',
  key: 'balance',
  align: "center",
  // width: '10%',
  scopedSlots: {
    customRender: 'balance'
  }
}, {
  title: '-',
  dataIndex: 'level_name',
  key: 'level_name',
  align: "center",
  // width: '10%',
  scopedSlots: {
    customRender: 'level_name'
  }
}, {
  title: '-',
  dataIndex: 'icon_url',
  key: 'icon_url',
  align: "center",
  // width: '10%',
  scopedSlots: {
    customRender: 'icon_url'
  }
}, {
  title: '-',
  dataIndex: 'sales',
  key: 'sales',
  align: "center",
  // width: '10%',
  scopedSlots: {
    customRender: 'sales'
  }
}, {
  title: '-',
  dataIndex: 'discount_rate',
  key: 'discount_rate',
  align: "center",
  // width: '5%',
  scopedSlots: {
    customRender: 'discount_rate'
  }
}, {
  title: '-',
  dataIndex: 'operation',
  key: 'operation',
  align: "center",
  // width: '8%',
  scopedSlots: {
    customRender: 'operation'
  }
}];
export { columns
// columnsbusiness
};